<template>
  <div class="progress">
    <div :title="title">{{ label }}</div>
    <div class="progress-bar_container" :style={width}>
      <template v-for="step in steps">
        <div v-if="step.name !== 'total'" :class="['progress-bar', step.name]" :style="{width: step.progress}" :title="`${step.name} ${step.count}`" ></div>
      </template>
    </div>
  </div>
</template>

<script>

// @todo add color to each step regarding the label
export default {
  name: 'hippolyte.progress',
  props: {
    event: Object,
    label: String,
    width: {
      type: String,
      default: '100%'
    }
  },
  watch: {
    event: 'updateSteps'
  },
  data () {
    return { total: 0, steps: [] }
  },
  methods: {
    updateSteps (event) {
      this.total = event.total
      this.steps.splice(
        0,
        this.steps.length,
        ...Object.entries(event)
          .map(([key, value]) => {
            return { name: key, progress: ((value / event.total) * 100) + '%', count: value }
      }))
    }
  },
  computed: {
    title () {
      return this.steps.map(step => `${step.name} ${step.count}`).join('\n')
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~/colors.styl'
.progress
  width 100%
  .progress-bar_container
    display flex
    height 10px
    border-radius 5px
    border 1px solid grey
  .progress-bar
    &.skiped
      background-color blue
    &.updated
      background-color green
    &.created
      background-color yellow
    &.published
      background-color purple
    &.unpublished
      background-color orange
    &.error
      background-color red
    &:last-child
      margin-right 0
</style>
