import { Notify } from '/notify'
import { Api } from '/api'
import { parseISO, add, endOfDay, startOfDay } from 'date-fns'
import { EventEmitter } from 'eventemitter3'
import pick from 'ramda/src/pick'
import omit from 'ramda/src/omit'
import { Status, StatusSet, Transitions, TransitionsSet } from '/interview/status'
import Recruiter from '/user/recruiter/recruiter.entity'
import Candidate from '/user/candidate/candidate.entity'
import Search from '/requirement/entity'
import { Solr, or, and, eq, search } from '/solr'
import { collectIds, solrItem } from '/fun.js'

const service = verb => `entity.Conciliation/${verb}`
const serviceStatus = verb => `entity.ConciliationStatus/${verb}`
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`
function updateIncludes (me, key, Model, data, socket) {
  const aug  = {}
  if (data && typeof data[key] !== 'undefined') {
    if(data[key] instanceof Model) {
      aug[key] = data[key]
    } else if ('object' === typeof data[key]) {
      aug[key] = me[key] ? me[key].setData(data[key]) : new Model(data[key], socket)
    } else if ('string' === typeof data[key] && !(me[key] && data[key] === me[key].id)) {
      aug[key] = new Model({ id: data[key] }, socket)
    }
  }
  return aug
}

function normalize (me, data, socket) {
  if (data) {
    const aug = {}
    Object.entries(Interview.modelIncludes)
      .reduce((aug, [key, Model]) => Object.assign(aug, updateIncludes(me, key, Model, data, socket)), aug)
    return Object.assign({}, omit(Object.keys(Interview.modelIncludes), data), aug)
  }
  return data
}

export default class Interview extends EventEmitter {
  // @todo link to factory
  constructor (data, socket) {
    super()
    this.domain = '/room/interview'
    this.socket = socket
    this.setData(data)
    this.loading = false
  }
  destroy () {
    this.removeAllListeners()
    factory.delete(this.id)
  }
  static modelIncludes = { recruiter: Recruiter, candidate: Candidate, search: Search }
  async state (transition, reason) {
    if (transition) {
      if (!TransitionsSet.includes(transition)) {
        throw Error('invalid transition name')
      }
      try {
        if (this.updating) {
          throw Error('already updating')
        }
        const updating = this.socket.service(service('WORKFLOW'), { id: this.id, transition, context: { reason } })
        this.setData({ updating, doing: transition })
        const interview = await updating
        this.setData(interview, { updating: null, doing: null })
      } catch {
        this.setData({ updating: null, doing: null })
      }
      return this
    }
  }

  setData (...data) {
    const delta = this.getDelta()
    const includes = pick(Object.keys(Interview.modelIncludes), this)
    let update = Object.assign(...data.map(d => normalize(this, d, this.socket)))
    if (update.statuses) {
      update.statuses.forEach(s => {
        if (s && s.date) {
          s.date = parseISO(s.date)
        }
      })
    }
    Object.assign(this, update)
    if (!this.statuses || !Array.isArray(this.statuses)) {
      this.statuses = []
    }
    if (update.statuses && this.statuses.length) {
      this.statuses.sort((a, b) => {
        if (a.order > b.order) {
          return -1
        }
        if (a.order < b.order)  {
          return 1
        }

        return 0
      })
      this.status = this.statuses[0].status
      const sent = this.statuses.find(s => s.status === Status.sent)
      this.sentAt = sent?.date
    }
    this.hasUpdate(delta, includes)
    return this
  }
  getDelta () {
    return JSON.stringify(this.marshall())
  }
  hasUpdate (delta, includes=false) {
    if (delta !== this.getDelta()) {
      return this.emit('update', this, JSON.parse(delta))
    }
    if (includes) { // does includes object has change (by refs)
      return Object.keys(Interview.modelIncludes)
        .map(k => includes[k] === this[k])
        .includes(false)
    }
  }
  isCreated () {
    return this.status === Status.created
  }
  isScheduled () {
    return this.status === Status.scheduled
  }
  suggest () {
    return this.state(Transitions.suggest)
  }
  isSuggested () {
    return this.status === Status.suggested
  }
  isSuggesting () {
    return this.doing === Transitions.suggest
  }
  canSchedule () {
    return this.isCreated() || this.isSuggested()
  }
  schedule () {
    return this.state(Transitions.schedule)
  }
  isScheduling () {
    return this.doing === Transitions.schedule
  }
  isSending () {
    return this.doing === Transitions.send
  }
  isSent () {
    return this.status === Status.sent
  }
  canSend () {
    return this.isScheduled() || this.isCreated() || this.isSuggested() || this.isAccepted()
  }
  canReject () {
    return this.isSent() || this.isOpened() || this.isStarted()
  }
  reject (reason) {
    return this.state(Transitions.reject, reason)
  }
  isRejected () {
    return this.status === Status.rejected
  }
  isRejecting () {
    return this.doing === Transitions.reject
  }
  canDiscard () {
    return this.isSuggested()
  }
  discard (reason) {
    return this.state(Transitions.discard, reason)
  }
  isDiscarded () {
    return this.status === Status.discarded
  }
  isDiscarding () {
    return this.doing === Transitions.discard
  }
  undiscard () {
    return this.state(Transitions.undiscard)
  }
  isUndiscarding () {
    return this.doing === Transitions.undiscard
  }
  start () {
    if (!this.canStart()) { throw Error('invalid transition state') }
    if (this.isOpened()) {
      return this.state(Transitions.start)
    }
    if (this.isArchived()) {
      return this.state(Transitions.unarchive)
    }
  }
  canStart () {
    return this.isSent() || this.isOpened() || this.isArchived()
  }
  isStarted () {
    return this.status === Status.started
  }
  isStarting () {
    return this.doing === Transitions.start
  }
  open () {
    if (!this.canOpen()) { throw Error('invalid transition state') }
    return this.state(Transitions.open)
  }
  send () {
    if (!this.canSend()) { throw Error('invalid transition state') }
    return this.state(Transitions.send)
  }
  canOpen () {
    return this.isSent()
  }
  isOpened () {
    return this.status === Status.opened
  }
  isOpening () {
    return this.doing === Transitions.open
  }
  recruit () {
    if (!this.canRecruit()) { throw Error('invalid transition state') }
    return this.state(Transitions.recruit)
  }
  canRecruit () {
    return this.isSent() || this.isOpened() || this.isStarted() || this.isMet()
  }
  isRecruited () {
    return this.status === Status.recruited
  }
  isRecruiting () {
    return this.doing === Transitions.recruit
  }
  meet () {
    if (!this.canMet()) { throw Error('invalid transition state') }
    return this.state(Transitions.meet)
  }
  canMeet () {
    return this.isSent() || this.isOpened() || this.isStarted()
  }
  isMet () {
    return this.status === Status.met
  }
  isMeeting () {
    return this.doing === Transitions.meet
  }
  archive () {
    if (!this.canArchive()) { throw Error('invalid transition state') }
    return this.state(Transitions.archive)
  }
  canArchive () {
    return !this.isArchived()
  }
  isArchived () {
    return this.status === Status.archived
  }
  isArchiving () {
    return this.doing === Transitions.archive
  }
  unarchive () {
    if (!this.canUnarchive()) { throw Error('invalid transition state') }
    return this.state(Transitions.unarchive)
  }
  canUnarchive () {
    return this.isArchived() || this.isRejected()
  }
  isUnarchiving () {
    return this.doing === Transitions.unarchive
  }
  isWaitingAcceptation () {
    return this.status === Status.waiting_acceptation
  }
  canRequireAcceptation() {
    return this.isCreated() || this.isSuggested()
  }
  isRequiringAcceptation() {
    return this.doing === Transitions.require_acceptation
  }
  requireAcceptation() {
    if (!this.canRequireAcceptation()) { throw Error('invalid transition state') }
    return this.state(Transitions.require_acceptation)
  }
  isDeclined () {
    return this.status === Status.declined
  }
  canDecline () {
    return this.isWaitingAcceptation()
  }
  isDeclining () {
    return this.doing === Transitions.decline
  }
  decline() {
    if (!this.canDecline()) { throw Error('invalid transition state') }
    return this.state(Transitions.decline)
  }
  isAccepted () {
    return this.status === Status.accepted
  }
  canAccept () {
    return this.isWaitingAcceptation()
  }
  isAccepting () {
    return this.doing === Transitions.accept
  }
  accept() {
    if (!this.canAccept()) { throw Error('invalid transition state') }
    return this.state(Transitions.accept)
  }
  isDoNotSent () {
    return this.status === Status.do_not_send
  }
  canCancel () {
    return this.isScheduled() || this.isAccepted()
  }
  isCanceling () {
    return this.doing === Transitions.cancel
  }
  cancel () {
    if (!this.canCancel()) { throw Error('invalid transition state') }
    return this.state(Transitions.cancel)
  }
  load () {
    if (this.loading) {
      return this.loading
    }
    if (this.id) {
      this.loading = this.socket.service(service('READ'), { id: this.id })
        .then(this.setData.bind(this))
        .then(() => Object.assign({
          loading: false,
          loaded: true
        }))
      return this.loading
    }
    if (this.candidate.id && this.recruiter.id) {
      this.loading = this.socket.service(service('QUERY'), {
        alias: 'c',
        class: 'Conciliation',
        parameters: [
          { type: cond('Parameter'), name: 'recruiter', value: this.recruiter.id },
          { type: cond('Parameter'), name: 'candidate', value: this.candidate.id }
        ],
        conditions: [
          {
            type: cond('Equals'),
            value: 'recruiter',
            subject: {
              type: cond('Field'),
              name: 'c.recruiter'
            }
          },
          {
            type: cond('Equals'),
            value: 'candidate',
            subject: {
              type: cond('Field'),
              name: 'c.candidate'
            }
          }
        ]
      })
        .then(list => list[0])
        .then(this.setData.bind(this))
        .then(() => Object.assign(this, {
          loading: false,
          loaded: true
        }))
    }

    return this.loading
  }
  marshall (...fields) {
    if (fields.length === 0) {
      const { status, statuses, socket, roomId, ...data } = this
      return Object.assign(
        pick(['id', 'statuses', 'status'], this),
        {
          recruiter: this.recruiter?.id,
          candidate: this.candidate?.id,
          search: this.search?.id
        }
      )
    }
    const interviewData = Object.assign({}, {
      recruiter: this.recruiter?.id,
      candidate: this.candidate?.id,
      search: this.search?.id
    }, this)
    return pick(fields, interviewData)
  }
  save (...fields) {
    try {
      const data = this.marshall(...(['id'].concat(fields)))
      if (!data.id) {
        Object.assign(data, {
          id: this.id || null,
          recruiter: this.recruiter.id,
          search: this.search.id,
          candidate: this.candidate.id,
          comment: this.comment || '',
          internalComment: this.internalComment || ''
        })
      }
      this.updating = this.socket.service(service('SAVE'), data)
      return this.updating.then((data) => this.setData({ updating: null }, data))
    } catch (err) {
      console.log(err)
      this.setData({ updating: null })
    }
  }
  roomId (account) {
    return [this.domain, account.id, this.candidate.id]
      .filter(t => t && t.length > 0)
      .join('/')
  }
  static isValidRoom (id) {
    // @todo more accurate uuid regexp
    return /^\/room\/interview\/[0-9a-f-]+\/[0-9a-z-]+$/.test(id) || /^\/conciliator\/[0-9a-f-]+\/[0-9a-z-]+$/.test(id)
  }
  // @dead ?
  static parseUrl () {
    let id, recruiter, candidate
    const url = new URL(window.location.href)
    if (url.hash) {
      let data = url.hash.split('/').filter(x => x && x.length > 3)
      if (data.length === 2) {
        [recruiter, candidate] = data
      }
      if (data.length === 1) {
        [id] = data
      }
    }
    return {
      recruiter: {
        id: recruiter
      },
      candidate: {
        id: candidate
      },
      id
    }
  }
}
let factory = new Map()

Interview.api = function (socket) {
  return new Api(socket, '/conciliations')
}

Interview.list = function (opts, socket, cancel) {
  if (opts.solr) {
    if (opts.count) {
      return Interview.count(opts, socket, cancel)
    }
    return Interview.solrList(opts, socket, cancel)
  }
  const args = {
    alias: 'c',
    class: 'Conciliation',
    parameters: [],
    conditions: [],
    joins: []
  }
  if (opts.states) {
    let states = opts.states
    if ('string' === typeof opts.states) {
      states = [opts.states]
    }
    if (Array.isArray(states)) {
      args.parameters.push({
        type: cond('Parameter'),
        name: 'status',
        value: states.filter(s => Status[s])
      })
      args.conditions.push({
        type: cond('In'),
        value: 'status',
        subject: {
          type: cond('Field'),
          name: 's.status'
        }
      })
    }
    // current
    args.parameters.push({
      type: cond('Parameter'),
      name: 'current',
      value: true
    })
    args.conditions.push({
      type: cond('Equals'),
      value: 'current',
      subject: {
        type: cond('Field'),
        name: 's.current'
      }
    })
    // joins
    args.joins.push({
      field: {
        type: cond('Field'),
        name: 'c.statuses'
      },
      alias: 's',
      type: 'right'
    })
  }
  if (opts.recruiter) {
    args.parameters.push({
      type: cond('Parameter'),
      name: 'recruiter',
      value: opts.recruiter.id
    })
    args.conditions.push({
      type: cond('Equals'),
      value: 'recruiter',
      subject: {
        type: cond('Field'),
        name: 'c.recruiter'
      }
    })
  }
  if (opts.candidates) {
    args.parameters.push({
      type: cond('Parameter'),
      name: 'candidate',
      value: opts.candidates.map(c => c.id)
    })
    args.conditions.push({
      type: cond('In'),
      value: 'candidate',
      subject: {
        type: cond('Field'),
        name: 'c.candidate'
      }
    })
  }
  if (opts.searches && Array.isArray(opts.searches) && opts.searches.length > 0) {
    args.parameters.push({
      type: cond('Parameter'),
      name: 'search',
      value: opts.searches.map(s => s.id)
    })
    args.conditions.push({
      type: cond('In'),
      value: 'search',
      subject: {
        type: cond('Field'),
        name: 'c.search'
      }
    })
  }

  if (opts.count) {
    args.count = true
  } else {
    if (opts.limit) {
      args.limit = opts.limit
    }

    if (opts.offset) {
      args.offset = opts.offset
    }
    if (opts.order) {
      args.order_by = [
        {
          field: {
            type: 'YesWeChat\\ServiceEntityBundle\\Query\\Condition\\Field',
            name: `s2.${opts.order.field}`
          },
          direction: opts.dir?.toUpperCase() || 'ASC'
        }
      ]
      args.parameters.push({
        type: cond('Parameter'),
        name: opts.order.status,
        value: opts.order.status
      })
      args.conditions.push({
        type: cond('Equals'),
        value: opts.order.status,
        subject: {
          type: cond('Field'),
          name: 's2.status'
        }
      })
      args.joins.push({
        field: {
          type: cond('Field'),
          name: 'c.statuses'
        },
        alias: 's2',
        type: 'right'
      })
    }
  }

  return socket.service(service('QUERY'), args, { cancel }).then(data => {
    if (opts.count) {
      return data.count
    }
    return data.map(i => {
      let interview
      if (factory.has(i.id)) {
        interview = factory.get(i.id)
      } else {
        interview = new Interview(i, socket)
        interview.loaded = true
        factory.set(i.id, interview)
      }
      return interview
    })
  })
}

Interview.statsList = function (opts, socket, cancel) {
  const req = new Solr({
    entity: 'ConciliationStatus'
  })
  if (opts.current) {
    req.query.push('current:true')
  }
  if (opts.status) {
    req.query.push(Array.isArray(opts.status) ? `(${or(...opts.status.map(eq('status'))).toString()})` : eq('status', opts.status))
  }
  const r = req.join({
    entity: 'Conciliation'
  })
  if (opts.a){
    r.query.push(`{!join entity=Search v="active:true"}`)
  }
  if (opts.searches) {
    r.query.push(...collectIds(opts.searches).map(eq('search')))
  }
  if (opts.recruiter) {
    r.query.push(eq('recruiter', typeof opts.recruiter === 'string' ? opts.recruiter : opts.recruiter.id))
  }
  if (opts.count) {
    req.limits(0)
  } else {
    req.limits(opts.limit, opts.offset)
  }
  return socket.service('entity_solr/QUERY', req.send(), { cancel })
    .then(data => {
      return data
    })
}

function sqlEntities (opts, socket, cancel) {
  const args = {
    alias: 'c',
    class: 'Conciliation',
    parameters: [],
    conditions: [],
    joins: []
  }
  if (opts.states) {
    let states = opts.states
    if (typeof opts.states === 'string') {
      states = [opts.states]
    }
    if (Array.isArray(states)) {
      args.parameters.push({
        type: cond('Parameter'),
        name: 'status',
        value: states.filter(s => Status[s])
      })
      args.conditions.push({
        type: cond('In'),
        value: 'status',
        subject: {
          type: cond('Field'),
          name: 's.status'
        }
      })
    }
    // current
    args.parameters.push({
      type: cond('Parameter'),
      name: 'current',
      value: true
    })
    args.conditions.push({
      type: cond('Equals'),
      value: 'current',
      subject: {
        type: cond('Field'),
        name: 's.current'
      }
    })
    // joins
    args.joins.push({
      field: {
        type: cond('Field'),
        name: 'c.statuses'
      },
      alias: 's',
      type: 'right'
    })
  }
  if (opts.recruiter) {
    args.parameters.push({
      type: cond('Parameter'),
      name: 'recruiter',
      value: opts.recruiter.id
    })
    args.conditions.push({
      type: cond('Equals'),
      value: 'recruiter',
      subject: {
        type: cond('Field'),
        name: 'c.recruiter'
      }
    })
  }
  if (opts.candidates) {
    args.parameters.push({
      type: cond('Parameter'),
      name: 'candidate',
      value: opts.candidates.map(c => c.id)
    })
    args.conditions.push({
      type: cond('In'),
      value: 'candidate',
      subject: {
        type: cond('Field'),
        name: 'c.candidate'
      }
    })
  }
  if (opts.searches && Array.isArray(opts.searches) && opts.searches.length > 0) {
    let searchesParam
    if (typeof opts.searches[0] === 'string') {
      searchesParam = opts.searches
    } else {
      searchesParam = opts.searches.map(s => s.id)
    }
    args.parameters.push({
      type: cond('Parameter'),
      name: 'search',
      value: searchesParam
    })
    args.conditions.push({
      type: cond('In'),
      value: 'search',
      subject: {
        type: cond('Field'),
        name: 'c.search'
      }
    })
  }

  if (opts.count) {
    args.count = true
  } else {
    if (opts.limit) {
      args.limit = opts.limit
    }

    if (opts.offset) {
      args.offset = opts.offset
    }
    if (opts.order) {
      args.order_by = [
        {
          field: {
            type: 'YesWeChat\\ServiceEntityBundle\\Query\\Condition\\Field',
            name: `s2.${opts.order.field}`
          },
          direction: opts.dir?.toUpperCase() || 'ASC'
        }
      ]
      args.parameters.push({
        type: cond('Parameter'),
        name: opts.order.status,
        value: opts.order.status
      })
      args.conditions.push({
        type: cond('Equals'),
        value: opts.order.status,
        subject: {
          type: cond('Field'),
          name: 's2.status'
        }
      })
      args.joins.push({
        field: {
          type: cond('Field'),
          name: 'c.statuses'
        },
        alias: 's2',
        type: 'right'
      })
    }
  }

  return socket.service(service('QUERY'), args, { cancel }).then(data => {
    if (opts.count) {
      return data.count
    }
    return data.map(c => Object.assign(Interview.create(c, socket), { loaded: true }))
  })
}
Interview.create = function (data, socket) {
  return new Interview(data, socket)
}

Interview.count = function (opts, socket, cancel) {
  const req = new Solr({
    entity: 'ConciliationStatus',
    raw: true,
    limit: 0,
    offset: 0,
    query: and('current:true')
  })
  const reqFacets = {
    facets: [{
      type: 'terms',
      name: 'status',
      field: 'status',
      mincount: 0,
      limit: opts.limit
    }]
  }
  if (opts.recruiter) {
    req.join({
      entity: 'Conciliation',
      query: and(eq('recruiter', opts.recruiter.id))
    })
  }
  if (opts.searches && Array.isArray(opts.searches) && opts.searches.length > 0) {
    req.join({
      entity: 'Conciliation',
      query: and(or(...collectIds(opts.searches).map(eq('search'))))
    })
  }
  if (['archived', 'closed', 'active'].some(prop => typeof opts[prop] === 'boolean')) {
    const v = []
    if( typeof opts.archived === 'boolean') {
      v.push(`{!join entity=Recruiter v=\\"archived:${opts.archived}\\"}`)
    }
    if (typeof opts.closed === 'boolean') {
      v.push(`{!join entity=Campaign v=\\"closed:${opts.closed}\\"}`)
    }
    if (typeof opts.active === 'boolean') {
      v.push(`active:${opts.active}`)
    }
    req.join({
      entity: 'Conciliation',
      query: `{!join entity=Search v="${v.map(a => `(${a})`).join(' AND ')}"}`
    })
  }
  return socket.service('entity_solr/QUERY', Object.assign(req.send(), reqFacets), { cancel })
}
Interview.getConciliationData = async function (opts, socket, cancel) {
  const req = new Solr({
    entity: 'ConciliationData',
    raw: true,
    query: eq('conciliation', opts.conciliation),
    fields: ['id', 'aiResult', 'aiScore', 'aiVersion', 'formAnswer', 'conciliation']
  })
  return await socket.service('entity_solr/QUERY', req.send(), { cancel })
}

Interview.saveConciliationData = function ({ id, field, value }, socket) {
  const data = {
    id,
    [field.name]: field.name === 'aiScore' ? value : JSON.stringify(value)
  }
  const entries = [{
    action: 'patch',
    schema: 'ConciliationData',
    entity: data
  }]
  return socket.service('entity_solr/UPSERT', { entries })
}

Interview.solrList = function (opts, socket, cancel) {
  let current
  if (!('current' in opts) || opts.current) {
    opts.current = true
    current = 'current:true'
  }
  const base = new Solr({
    entity: 'ConciliationStatus',
    raw: true,
    query: and(current)
  })
  const cr = base.relation({
    entity: 'Conciliation',
    name: '_conciliation'
  })
    .from('conciliation')
    .to('id')
  const cj = base.join({ entity: 'Conciliation' })

  const cs = cr.relation({
    entity: 'ConciliationStatus',
    name: 'statuses'
  })
  cr.relation({
      entity: 'Candidate',
      name: 'candidate'
    },
    {
      entity: 'ConciliationData',
      name: 'aiData',
      fromField: 'id',
      toField: 'conciliation',
      fields: ['aiScore']
    },
    {
      entity: 'Reachable',
      name: 'reachable',
      fromField: 'id',
      toField: 'conciliation'
    }
  )
  const sub = current ? and(current) : and();
  if (opts.states) {
    let states = opts.states;
    if (typeof opts.states === 'string') {
      states = [opts.states];
    }
    if (states.length) {
      sub.push(or(...states.map((s) => 'status:' + s)));
    }
  }
  cj.query.push(`{!join entity=ConciliationStatus from=id to=conciliation v="${sub.toString()}"}`);
  if (opts.start && opts.end) {
    base.parameter(
      'start',
      add(startOfDay(opts.start), { minutes: -opts.start.getTimezoneOffset() }).toISOString()
    );
    base.parameter(
      'end',
      add(endOfDay(opts.end), { minutes: -opts.end.getTimezoneOffset() }).toISOString()
    );
    base.query.push('date:[@start TO @end]');
  }
  if (opts.recruiter) {
    cj.query.push(eq('recruiter', opts.recruiter.id));
  }
  if (typeof opts.bookmark !== 'undefined' && opts.bookmark !== null) {
    cj.query.push(eq('bookmark', '' + opts.bookmark));
  }
  if (opts.candidates && opts.candidates.length) {
    cj.query.push(or(...collectIds(opts.candidates).map(eq('candidate'))));
  }
  if (opts.searches && Array.isArray(opts.searches) && opts.searches.length > 0) {
    cj.query.push(or(...collectIds(opts.searches).map(eq('search'))));
  }
  if (opts.query) {
    cr.join({
      entity: 'Candidate',
      query: search('global_search', opts.query)
    });
  }
  if (opts.includes) {
    if (opts.includes.reasons) {
      cs.relation({
        name: 'reasons',
        entity: 'ConciliationStatusReason',
        fromField: 'reason',
        toField: 'id'
      });
    }
    if (opts.includes.recruiter) {
      cr.relation({ name: 'recruiter', entity: 'Recruiter' });
    }
    if (opts.includes.search) {
      const s = cr.relation({ name: 'search', entity: 'Search' });
      if (opts.includes.search.trade) {
        s.relation({ name: 'trade', entity: 'Trade' });
      }
      if (opts.includes.search.location) {
        s.relation({
          name: 'location',
          entity: 'Location',
          fromField: 'location',
          toField: 'id'
        });
      }
    }
  } else {
    cr.relation({
      entity: 'Search',
      name: 'search',
      fields: ['title']
    });
  }
  base.sorts(opts.order?.field ?? 'date', opts.dir);

  base.limits(opts.limit, opts.offset);

  return socket.service('entity_solr/QUERY', base.send(), { cancel }).then(data => {
    return {
      numFound: data.numFound,
      docs: data.docs.map(cs => {
        const c = cs._conciliation.docs[0]
        c.statuses.docs.map(s => { s.reasons = solrItem({}, s.reasons) })
        return Interview.create(Object.assign(c, {
          aiData: c.aiData.docs[0],
          statuses: c.statuses.docs,
          candidate: Candidate.create(solrItem({}, c.candidate), socket),
          search: new Search(solrItem({
            location: solrItem({}),
            trade: solrItem({})
          }, c.search), socket),
          recruiter: new Recruiter(solrItem({}, c.recruiter), socket),
          reachable: c.reachable.docs[0]
        }), socket)
      })
    }
  })
}

Interview.getStatusReasons = function (opts, socket, cancel) {
  const req = new Solr({
    entity: 'ConciliationStatusReason',
    raw: true,
    limit: 1000,
    offset: 0
  })
  return socket.service('entity_solr/QUERY', req.send(), { cancel })
}

Interview.getReachables = function (opts, socket, cancel) {
    const req = new Solr({
    entity: 'Reachable',
    raw: true,
    query: and()
  })
  if (opts.conciliation) {
    req.query.push(eq('conciliation', opts.conciliation))
  }
  if (opts.candidate) {
    req.query.push(eq('candidate', opts.candidate))
  }
  return socket.service('entity_solr/QUERY', req.send(), { cancel })
}


Interview.compare = function (a, b) {
  if (!a.interview?.sentAt || !b.interview?.sentAt) {
    return 0
  }
  if (a.interview.sentAt > b.interview.sentAt) {
    return -1
  }
  if (a.interview.sentAt < b.interview.sentAt) {
    return 1
  }
  return 0
}
