<template>
  <div class="sync-container">
    <template v-for="tracker in trackers" :keys="tracker.name">
      <tracker :event="tracker.tracker" :label="tracker.name" width="200px"></tracker>
    </template>
  </div>
</template>

<script>

import Tracker from './progress.vue'
export default {
  name: 'hippolyte.sync',
  props: {
    recruiter: Object,
    service: String,
    realm: String
  },
  components: { Tracker },
  data () {
    return { trackers: [] }
  },
  watch: {
    recruiter (recruiter, old) {
      this.trackers.splice(0, this.trackers.length)
      this.removeListenSync(old, this.service)
      this.listenSync(recruiter, this.service)
    },
    service (service, old) {
      this.trackers.splice(0, this.trackers.length)
      this.removeListenSync(this.recruiter, old)
      this.listenSync(this.recruiter, service)
    }
  },
  mounted () {
    this.listenSync(this.recruiter, this.service)
  },
  destroyed () {
    this.removeListenSync(this.recruiter, this.service)
  },
  methods: {
    listenSync (recruiter, service) {
      const room = `/ats/${service}/recruiter/${recruiter.id}/${this.realm}`
      this.$socket.sub(room, 'PROGRESS', this.onProgress.bind(this))
      this.$socket.sub(room, 'SCAN_START', this.onScanStart.bind(this))
      this.$socket.sub(room, 'SCAN_DONE', this.onScanDone.bind(this))
      this.$socket.sub(room, 'SCAN_FAIL', this.onScanFail.bind(this))
    },
    removeListenSync (recruiter, service) {
      const room = `/ats/${service}/recruiter/${recruiter.id}/${this.realm}`
      this.$socket.unsub(room, 'PROGRESS')
      this.$socket.unsub(room, 'SCAN_START')
      this.$socket.unsub(room, 'SCAN_DONE')
      this.$socket.unsub(room, 'SCAN_FAIL')
    },
    onProgress (event) {
      Object.entries(event.body).forEach(([name, tracker]) => {
        const at = this.trackers.findIndex(tracker => tracker.name === name)
        if (at === -1) {
          this.trackers.push({ name, tracker })
        } else {
          Object.assign(this.trackers[at], { name, tracker })
        }
      })
    },

    onScanStart (event) {
      // @todo extract realm from the room
      this.$toast('debut de scan', { theme: 'info' })
    },

    onScanDone (event) {
      this.$toast('scan terminé', { theme: 'success' })
    },

    onScanFail (event) {
      this.$toast('scan échoué', { theme: 'error' })
    }

  }
}
</script>

<style lang="stylus" scoped>
@require '~/colors.styl'
.sync-container
  display flex
  flex-direction column
.sync-container
  width 100%
</style>
